import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Button,
  Card,
  CardHeader,
  Divider,
  CardContent,
  CircularProgress,
  TextField,
  Alert,
  Grid,
  Checkbox,
  ListItemText,
  FormControlLabel
} from '@material-ui/core';
import { formatPhoneNumber } from '../../help/telephone-mask';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useGet, usePut } from '../../API/request';
import { BallTriangle } from 'react-loader-spinner';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import '../../styles/Avatar/style.css';
import { workingSchedule, roles } from '../../Constants';

const MasterEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const getU = useGet();
  const putU = usePut();
  const [cities, setCities] = useState([]);
  const [isLoaded, setIsLoaded] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [nothingChanged, setNothingChanged] = useState(true);
  const [uploadedImg, setUploadedImg] = useState('/static/images/defphoto.jpg');
  const [values, setValues] = useState({
    phone: '',
    username: '',
    fio: '',
    password: '',
    confirm: '',
    img: '',
    beginSchedule: '',
    cities: [],
    schedule: '',
    tags: [],
    timeBegin: '',
    timeEnd: '',
    bet: '',
    generalCompensation: null,
    lift_compensation: null,
    full_time: false,
    role: ''
  });

  const [errors, setErrors] = useState({
    phone: false,
    username: false,
    fio: false,
    password: false,
    confirm: false,
    img: false,
    beginSchedule: '',
    cities: false,
    schedule: false,
    tags: false,
    timeBegin: false,
    timeEnd: false,
    bet: false,
    generalCompensation: false,
    lift_compensation: false,
    full_time: false,
    role: false
  });
  const [tags, setTags] = useState([]);
  const [errorValue, setErrorValue] = useState(false);
  const [alert, setAlert] = useState({
    txt: '',
    isAvatarVisible: false,
    isInfoVisible: false,
    isPasswordVisible: false,
    type: 'error'
  });

  const handleChange = (event) => {
    setNothingChanged(false);
    console.log(event.target.value);
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    setErrors({
      ...errors,
      [event.target.name]: false
    });
  };

  const showAlert = (type, text, isVisible) => {
    setAlert({
      txt: text,
      type,
      [isVisible]: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        [isVisible]: false
      });

      setSubmitDisabled(false);
    }, 2500);
  };
  const handleChangePhone = (event) => {
    setNothingChanged(false);
    const formattedNumber = formatPhoneNumber(event.target.value);
    if (formattedNumber.length > 16) return;
    setValues({ ...values, phone: formattedNumber });
  };

  // const validateInfo = () => {
  //   let formErrors = { ...errors };

  //   if (values.phone === '') {
  //     setErrorValue(true);
  //     formErrors.phone = false;
  //     showAlert('error', 'Поле phone не должно быть пустым', 'isInfoVisible');
  //   }
  //   if (values.username === '') {
  //     setErrorValue(true);
  //     formErrors.username = false;
  //     showAlert(
  //       'error',
  //       'Поле User Name не должно быть пустым',
  //       'isInfoVisible'
  //     );
  //   }
  //   if (values.fio === '') {
  //     setErrorValue(true);
  //     formErrors.fio = false;
  //     showAlert('error', 'Поле FIO не должно быть пустым', 'isInfoVisible');
  //   }
  //   if (values.generalCompensation === '') {
  //     setErrorValue(true);
  //     formErrors.generalCompensation = false;
  //     showAlert(
  //       'error',
  //       'Поле Общая компенсация не должно быть пустым',
  //       'isInfoVisible'
  //     );
  //   }
  //   if (values.schedule === '') {
  //     setErrorValue(true);
  //     formErrors.schedule = false;
  //     showAlert(
  //       'error',
  //       'Поле Рабочий график не должно быть пустым',
  //       'isInfoVisible'
  //     );
  //   }
  //   if (values.beginSchedule === '') {
  //     setErrorValue(true);
  //     formErrors.beginSchedule = false;
  //     showAlert('error', 'Поле Дата не должно быть пустым', 'isInfoVisible');
  //   }
  //   if (values.timeBegin === '') {
  //     setErrorValue(true);
  //     formErrors.timeBegin = false;
  //     showAlert(
  //       'error',
  //       'Поле Время начала не должно быть пустым',
  //       'isInfoVisible'
  //     );
  //   }

  //   if (values.timeEnd === '') {
  //     setErrorValue(true);
  //     formErrors.timeEnd = false;
  //     showAlert(
  //       'error',
  //       'Поле Время окончания не должно быть пустым',
  //       'isInfoVisible'
  //     );
  //   }
  //   if (values.bet === '') {
  //     setErrorValue(true);
  //     formErrors.bet = false;
  //     showAlert('error', 'Поле Процент не должно быть пустым', 'isInfoVisible');
  //   }
  //   if (values.lift_compensation === null) {
  //     setErrorValue(true);
  //     formErrors.lift_compensation = false;
  //     showAlert(
  //       'error',
  //       'Поле Процент с допродаж не должно быть пустым',
  //       'isInfoVisible'
  //     );
  //   }
  //   if (values.cities.length === 0) {
  //     setErrorValue(true);
  //     formErrors.cities = false;
  //     showAlert('error', 'Поле Города не должно быть пустым', 'isInfoVisible');
  //   }
  //   if (values.tags.length === 0) {
  //     setErrorValue(true);
  //     formErrors.tags = false;
  //     showAlert('error', 'Поле Навыки не должно быть пустым', 'isInfoVisible');
  //   }

  //   setErrors(formErrors);
  // };

  const validatePassword = () => {
    let validComplete = true;
    let formErrors = { ...errors };

    if (values.password) {
      if (!values.password) {
        validComplete = false;
        formErrors.password = true;
        showAlert(
          'error',
          'Поле Пароль не должно быть пустым',
          'isPasswordVisible'
        );
      } else if (values.password.length < 8) {
        validComplete = false;
        formErrors.password = true;
        showAlert(
          'error',
          'Пароль должен содержать более 8 символов',
          'isPasswordVisible'
        );
      }

      if (values.password !== values.confirm) {
        validComplete = false;
        formErrors.confirm = true;
        showAlert('error', 'Пароли не совпадают', 'isPasswordVisible');
      }
    }

    setErrors(formErrors);
  };

  const avaUploaded = (event) => {
    setNothingChanged(false);
    setUploadedImg(URL.createObjectURL(event.target.files[0]));
    setValues({
      ...values,
      img: event.target.files[0]
    });
  };

  const submitAvatar = async () => {
    if (nothingChanged) {
      showAlert('error', 'Нет изменений');
      return;
    }

    setSubmitDisabled(true);

    let data = new FormData();
    data.append('avatar', values.img);
    console.log(values.img);

    putU(`admin/users/avatar/${id}`, data)
      .then((resp) => {
        console.log(resp);
        if (resp.status === 'success') {
          showAlert('success', 'Данные успешно обновлены', 'isAvatarVisible');
        } else {
          showAlert('error', resp.message, 'isAvatarVisible');
        }
      })
      .catch((err) => {
        showAlert('error', 'Ошибка сервера', 'isAvatarVisible');
      })
      .finally(() => {
        setSubmitDisabled(false);
      });
  };

  const formateDate = (value) => {
    return `${value}:00:00`;
  };
  console.log(nothingChanged);
  const submitInfo = async () => {
    console.log(nothingChanged, 'changes');
    if (nothingChanged) {
      showAlert('error', 'Нет изменений', 'isInfoVisible');
      return;
    }
    if (values.phone === '') {
      showAlert(
        'error',
        'Поле номер телефона не должно быть пустым',
        'isInfoVisible'
      );
      return;
    }
    if (values.username === '') {
      showAlert(
        'error',
        'Поле User Name не должно быть пустым',
        'isInfoVisible'
      );
      return;
    }
    if (values.fio === '') {
      showAlert('error', 'Поле FIO не должно быть пустым', 'isInfoVisible');
      return;
    }
    if (values.generalCompensation === '') {
      showAlert(
        'error',
        'Поле Общая компенсация не должно быть пустым',
        'isInfoVisible'
      );
      return;
    }
    if (values.schedule === '') {
      showAlert(
        'error',
        'Поле Рабочий график не должно быть пустым',
        'isInfoVisible'
      );
      return;
    }
    if (values.beginSchedule === '') {
      showAlert('error', 'Поле Дата не должно быть пустым', 'isInfoVisible');
      return;
    }
    if (values.timeBegin === '') {
      showAlert(
        'error',
        'Поле Время начала не должно быть пустым',
        'isInfoVisible'
      );
      return;
    }

    if (values.timeEnd === '') {
      showAlert(
        'error',
        'Поле Время окончания не должно быть пустым',
        'isInfoVisible'
      );
      return;
    }
    if (values.bet === '') {
      showAlert('error', 'Поле Процент не должно быть пустым', 'isInfoVisible');
      return;
    }
    if (values.lift_compensation === null) {
      showAlert(
        'error',
        'Поле Процент с допродаж не должно быть пустым',
        'isInfoVisible'
      );
      return;
    }
    if (values.cities.length === 0) {
      showAlert('error', 'Поле Города не должно быть пустым', 'isInfoVisible');
      return;
    }
    if (values.tags.length === 0) {
      showAlert('error', 'Поле Навыки не должно быть пустым', 'isInfoVisible');
      return;
    }

    if (values.role === '') {
      showAlert(
        'error',
        'Поле роль обязательно к заполнению ',
        'isInfoVisible'
      );
      return;
    }
    setSubmitDisabled(true);

    const dataForUsers = {
      username: values.username,
      role:
        values.role === 'Regional manager' ? 'regional_manager' : values.role
    };

    const dataForParams = {
      schedule: values.schedule,
      fio: values.fio,
      generalCompensation: values.generalCompensation,
      beginSchedule: values.beginSchedule,
      timeBegin: formateDate(values.timeBegin),
      timeEnd: formateDate(values.timeEnd),
      cities: values.cities.join(','),
      tags: values.tags.join(','),
      bet: values.bet,
      lift_compensation: values.lift_compensation,
      full_time: values.role === 'Regional manager' ? false : values.full_time,
      phone:
        values.phone.length > 16
          ? values.phone.substring(0, values.phone.length - 1)
          : values.phone
    };
    console.log(dataForParams);
    putU(`admin/users/${id}`, dataForUsers)
      .then((resp) => {
        if (resp.status === 'error') {
          showAlert('error', `Ошибка: ${resp.message}`, 'isInfoVisible');
        }
      })
      .catch((err) => {
        showAlert('error', `Ошибка сервера: ${err.message}`, 'isInfoVisible');
      })
      .finally(() => {});

    putU(`admin/users/param/${id}`, dataForParams)
      .then((resp) => {
        if (resp.status === 'success') {
          showAlert('success', 'Данные успешно обновленны', 'isInfoVisible');
          // navigate(-1);
        } else {
          showAlert('error', `Ошибка: ${resp.message}`, 'isInfoVisible');
        }
      })
      .catch((err) => {
        showAlert('error', `Ошибка сервера: ${err.message}`, 'isInfoVisible');
      })
      .finally(() => {
        setSubmitDisabled(false);
      });
  };

  const submitPassword = async () => {
    if (nothingChanged) {
      showAlert('error', 'Нет изменений', 'isPasswordVisible');
      return;
    }

    if (validatePassword()) {
      setSubmitDisabled(true);

      const data = {
        password: values.password
      };

      putU(`admin/users/password/${id}`, data)
        .then((resp) => {
          if (resp.status === 'success') {
            showAlert(
              'success',
              'Данные успешно обновленны',
              'isPasswordVisible'
            );
            setValues({ ...values, password: '', confirm: '' });
          } else {
            showAlert('error', `Ошибка: ${resp.message}`, 'isPasswordVisible');
          }
        })
        .catch((err) => {
          showAlert(
            'error',
            `Ошибка сервера: ${err.message}`,
            'isPasswordVisible'
          );
        })
        .finally(() => {
          setSubmitDisabled(false);
        });
    }
  };

  useEffect(() => {
    setIsLoaded(true);

    getU(`ticket_category`).then((resp) => {
      if (resp.status === 'success') {
        setTags(resp.data.category.map((tag) => tag.category_name));
      }
    });

    getU(`region`).then((resp) => {
      if (resp.status === 'success') {
        setCities(resp.data.region.map((city) => city.region_name));
      }
    });

    getU(`admin/masters/${id}`)
      .then((res) => {
        const data = res.data.master;
        console.log(data, 'data');
        setValues({
          ...values,
          phone: data.param.phone ? data.param.phone : '',
          username: data.username,
          fio: data.param.fio,
          img: data.avatar,
          beginSchedule:
            data.param.beginSchedule !== null
              ? new Date(data.param.beginSchedule).toISOString().split('T')[0]
              : [],
          cities:
            data.param.cities !== null ? data.param.cities.split(',') : [],
          schedule: data.param.schedule,
          tags: data.param.tags !== null ? data.param.tags.split(',') : [],
          timeBegin: data.param.timeBegin.slice(0, 2),
          timeEnd: data.param.timeEnd.slice(0, 2),
          bet: data.param.bet,
          generalCompensation: data.param.generalCompensation,
          lift_compensation: data.param.lift_compensation,
          full_time: data.param.full_time === null || 0 ? false : true,
          role:
            data.role === 'regional_manager' ? 'Regional manager' : data.role
        });
      })
      .finally(() => {
        setIsLoaded(false);
      });
  }, []);
  console.log(values.phone);
  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Edit user</title>
      </Helmet>
      <Box className="headerWrapper">
        <Box className="headerTitle">
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Назад
          </Button>
          Мастера
        </Box>
        <ul className="headerList">
          <li onClick={() => navigate(-1)}>Мастера</li>
          <li>/</li>
          <li>Редактирование</li>
        </ul>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mr: 3 }}>
        <Box sx={{ marginLeft: 2 }}>
          <Link to={`/app/schedule/edit/${id}`}>
            <Button color="primary" variant="contained">
              Редактирование графика
            </Button>
          </Link>
        </Box>
      </Box>
      {/*image*/}
      <Box sx={{ backgroundColor: 'background.default', pt: 3 }}>
        <Container maxWidth={false}>
          <Box sx={{ mb: 1 }}>
            <Alert
              severity={alert.type}
              style={{ display: alert.isAvatarVisible ? 'flex' : 'none' }}
            >
              {alert.txt}
            </Alert>
          </Box>
          <Box
            sx={{
              paddingBottom: 1
            }}
          >
            <form>
              <Card>
                <CardHeader title="Редактирование аватарки" />
                <Divider />
                <CardContent sx={{ position: 'relative' }}>
                  <div className="itemWrapper">
                    <div className="container">
                      <input
                        accept="xlsx/*"
                        type="file"
                        style={{ display: 'none' }}
                        id={1}
                        onChange={(event) => avaUploaded(event, 1)}
                      />
                      <label htmlFor={1}>
                        <img
                          src={
                            values.img !== '' && typeof values.img === 'string'
                              ? `https://rapplegroup.backend.wtsdemo.ru/public/uploads/avatars/${values.img}`
                              : uploadedImg
                          }
                          className="itemImg"
                        />
                        <div className="middle" />
                      </label>
                    </div>
                    <div className="help-text">
                      Доступны следующие расширения: .png .jpg .svg .bmp .tga
                      .webp
                    </div>
                  </div>
                </CardContent>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={submitAvatar}
                    disabled={submitDisabled}
                  >
                    Сохранить
                  </Button>
                </Box>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>

      {/*info*/}
      <Box sx={{ backgroundColor: 'background.default', pt: 3 }}>
        <Container maxWidth={false}>
          <Box sx={{ mb: 1 }}>
            <Alert
              severity={alert.type}
              style={{ display: alert.isInfoVisible ? 'flex' : 'none' }}
            >
              {alert.txt}
            </Alert>
          </Box>
          <Box
            sx={{
              paddingBottom: 1
            }}
          >
            <form>
              <Card>
                <CardHeader title="Редактирование пользователя" />
                <Divider />
                <CardContent sx={{ position: 'relative' }}>
                  <TextField
                    fullWidth
                    label="Имя пользователя"
                    margin="normal"
                    name="username"
                    onChange={handleChange}
                    type="text"
                    value={values.username}
                    variant="outlined"
                    error={errors.username}
                  />
                  <TextField
                    fullWidth
                    label="ФИО"
                    margin="normal"
                    name="fio"
                    onChange={handleChange}
                    type="text"
                    value={values.fio}
                    variant="outlined"
                    error={errors.fio}
                  />
                  <TextField
                    fullWidth
                    label="+7 (900)-000-00-00"
                    margin="normal"
                    name="phone"
                    onChange={handleChangePhone}
                    type="text"
                    value={values.phone}
                    variant="outlined"
                    error={errors.phone}
                  />
                  <TextField
                    fullWidth
                    label="Общая компенсация"
                    margin="normal"
                    name="generalCompensation"
                    onChange={handleChange}
                    type="number"
                    value={values.generalCompensation}
                    variant="outlined"
                    error={errors.generalCompensation}
                    inputProps={{
                      min: '0'
                    }}
                  />
                  <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                    <InputLabel id="schedule">Рабочий график</InputLabel>
                    <Select
                      labelId="Schedule"
                      name="schedule"
                      value={values.schedule}
                      label="Рабочий график"
                      onChange={handleChange}
                    >
                      {workingSchedule?.map((item, i) => (
                        <MenuItem value={item} key={i}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                    <InputLabel id="role">Роль</InputLabel>
                    <Select
                      labelId="Role"
                      name="role"
                      value={values.role}
                      label="Роль"
                      onChange={handleChange}
                    >
                      {roles?.map((item, i) => (
                        <MenuItem value={item} key={i}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    sx={{ width: 250, mr: 5 }}
                    margin="normal"
                    id="beginSchedule"
                    label="Дата начала отсчета графика"
                    type="date"
                    name="beginSchedule"
                    value={values.beginSchedule}
                    onChange={handleChange}
                    // defaultValue={`${new Date().getFullYear()}-${
                    //   new Date().getMonth() + 1 < 10 ? '0' : ''
                    // }${new Date().getMonth() + 1}-${new Date().getDate()}`}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      max: '9999-12-31'
                    }}
                  />
                  <TextField
                    sx={{ width: 120, mr: 5 }}
                    margin="normal"
                    id="timeBegin"
                    label="Время начала"
                    type="number"
                    name="timeBegin"
                    value={values.timeBegin}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      max: '23',
                      min: '0'
                    }}
                  />
                  <TextField
                    sx={{ width: 120, mr: 5 }}
                    margin="normal"
                    id="timeEnd"
                    label="Время окончания"
                    type="number"
                    name="timeEnd"
                    value={values.timeEnd}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      max: '23',
                      min: '0'
                    }}
                  />
                  <TextField
                    sx={{ width: 100, mr: 5 }}
                    margin="normal"
                    id="bet"
                    label="Процент %"
                    type="number"
                    name="bet"
                    value={values.bet}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      max: '100',
                      min: '0'
                    }}
                  />
                  <TextField
                    sx={{ width: 175 }}
                    margin="normal"
                    id="lift_compensation"
                    label="Процент с допродаж %"
                    type="number"
                    name="lift_compensation"
                    value={values.lift_compensation}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      max: '100',
                      min: '0'
                    }}
                  />

                  <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                    <InputLabel id="cities">Города</InputLabel>
                    <Select
                      labelId="cities"
                      name="cities"
                      value={values.cities}
                      label="Города"
                      multiple
                      renderValue={(selected) => selected.join(', ')}
                      onChange={handleChange}
                    >
                      {cities?.map((city, i) => (
                        <MenuItem value={city} key={i}>
                          <Checkbox
                            checked={
                              city === values.cities.find((el) => el === city)
                            }
                          />
                          <ListItemText primary={city} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                    <InputLabel id="tags">Навыки</InputLabel>
                    <Select
                      labelId="tags"
                      name="tags"
                      value={values.tags}
                      label="Навыки"
                      multiple
                      renderValue={(selected) => selected.join(', ')}
                      onChange={handleChange}
                    >
                      {tags?.map((tag, i) => (
                        <MenuItem value={tag} key={i}>
                          <Checkbox
                            checked={
                              tag === values.tags.find((el) => el === tag)
                            }
                          />
                          <ListItemText primary={tag} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {values.role === 'master' && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.full_time}
                          onChange={() => {
                            setValues((prev) => ({
                              ...prev,
                              full_time: !prev.full_time
                            }));
                            setNothingChanged(false);
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label="Штатный сотрудник"
                      labelPlacement="bottom"
                    />
                  )}
                </CardContent>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={submitInfo}
                    disabled={submitDisabled}
                  >
                    Сохранить
                  </Button>
                </Box>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>

      {/*password*/}
      <Box sx={{ backgroundColor: 'background.default', pt: 3 }}>
        <Container maxWidth={false}>
          <Box sx={{ mb: 1 }}>
            <Alert
              severity={alert.type}
              style={{ display: alert.isPasswordVisible ? 'flex' : 'none' }}
            >
              {alert.txt}
            </Alert>
          </Box>
          <Box>
            <form>
              <Card>
                <CardHeader title="Редактирование пароля" />
                <Divider />
                <CardContent sx={{ position: 'relative' }}>
                  <TextField
                    sx={{ mb: 2 }}
                    fullWidth
                    label="Пароль"
                    name="password"
                    onChange={handleChange}
                    required
                    type="password"
                    value={values.password}
                    variant="outlined"
                    error={errors.password}
                    helperText={
                      errors.password &&
                      "Password shouldn't be shorter than 6 characters"
                    }
                  />
                  <TextField
                    fullWidth
                    label="Подтверждение пароля"
                    name="confirm"
                    onChange={handleChange}
                    type="password"
                    required
                    value={values.confirm}
                    variant="outlined"
                    error={errors.confirm}
                    helperText={errors.confirm && 'Passwords are different'}
                  />
                </CardContent>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={submitPassword}
                    disabled={submitDisabled}
                  >
                    Сохранить
                  </Button>
                </Box>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default MasterEdit;
