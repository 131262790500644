import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Button,
  TextField,
  CardContent,
  Checkbox,
  ListItemText,
  FormControlLabel
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import { useGet, usePost, usePut } from '../../API/request';
import React, { useEffect, useState } from 'react';
import { BallTriangle } from 'react-loader-spinner';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/core/Alert';
import { workingSchedule, roles } from '../../Constants';
import '../../styles/All.css';
import { formatPhoneNumber } from '../../help/telephone-mask';
import { RollerShadesRounded } from '@mui/icons-material';


const MasterAdd = () => {
  const navigate = useNavigate();
  const postU = usePost();
  const getU = useGet();
  const [isLoaded, setIsLoaded] = useState(true);
  const [checkForSendParam, setCheckForSendParam] = useState(false);
  const [isValidatephone, setIsValidatephone] = useState(true);
  const [values, setValues] = useState({
    phone: '',
    username: '',
    master_id: null,
    fio: '',
    schedule: '5/2',
    generalCompensation: null,
    beginSchedule: '',
    timeBegin: '',
    timeEnd: '',
    password: '',
    confirm: '',
    cities: [],
    bet: '',
    tags: [],
    lift_compensation: null,
    full_time: false,
    role: ''
  });
  const [errors, setErrors] = useState({
    phone: false,
    username: false,
    fio: false,
    password: false,
    confirm: false,
    schedule: false,
    beginSchedule: false,
    timeBegin: '',
    timeEnd: '',
    bet: false,
    cities: false,
    tags: false,
    generalCompensation: false,
    lift_compensation: false,
    full_time: false,
    role: false,
  });
  const [cities, setCities] = useState([]);
  const [tags, setTags] = useState([]);
  const [success, setSuccess] = useState(true);
  const [isShowLoader, setIsShowLoader] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
console.log(values.schedule)
  const [alert, setAlert] = useState({
    txt: '',
    isVisible: false,
    type: 'error'
  });

  const handleChange = (event) => {
    console.log(event.target.name);
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    setErrors({
      ...errors,
      [event.target.name]: false
    });
  };

  const showAlert = (type, text) => {
    setAlert({
      txt: text,
      type,
      isVisible: true
    });

    setTimeout(() => {
      setAlert({
        txt: text,
        type,
        isVisible: false
      });

      setSubmitDisabled(false);
    }, 1400);
  };

  const handleChangePhone = (event) => {
    const formattedNumber = formatPhoneNumber(event.target.value);
    console.log(formattedNumber);
    if (formattedNumber.length > 16) return;
    setValues({ ...values, phone: formattedNumber });
  };

  const validate = () => {
    let validComplete = true;
    let formErrors = { ...errors };

    if (values.schedule === '') {
      validComplete = false;
      formErrors.role = false;
      showAlert('error', 'Поле Рабочий график не должно быть пустым');
    }

    if (values.phone === '') {
      validComplete = false;
      formErrors.phone = false;
      showAlert('error', 'Поле phone number не должно быть пустым');
    } else if (!isValidatephone) {
      validComplete = false;
      formErrors.phone = false;
      showAlert(
        'error',
        'Вы передели в поле phone number не корректные данные'
      );
    }

    if (values.username === '') {
      validComplete = false;
      formErrors.username = false;
      showAlert('error', 'Поле User Name не должно быть пустым');
    }
    if (values.fio === '') {
      validComplete = false;
      formErrors.fio = false;
      showAlert('error', 'Поле FIO не должно быть пустым');
    }
    if (values.generalCompensation === null) {
      validComplete = false;
      formErrors.generalCompensation = false;
      showAlert('error', 'Поле Общая компенсация не должно быть пустым');
    }

    if (values.password === '') {
      validComplete = false;
      formErrors.password = false;
      showAlert('error', 'Поле Пароль не должно быть пустым');
    } else if (values.password.length < 8) {
      validComplete = false;
      formErrors.password = false;
      showAlert('error', 'Пароль должен содержать более 8 символов');
    }

    if (values.confirm === '') {
      validComplete = false;
      formErrors.confirm = false;
      showAlert('error', 'Поле Пароль не должно быть пустым');
    } else if (values.confirm.length < 8) {
      validComplete = false;
      formErrors.confirm = false;
      showAlert('error', 'Пароль должен содержать более 8 символов');
    } else if (values.confirm !== values.password) {
      validComplete = false;
      formErrors.confirm = false;
      showAlert('error', 'Пароли должны совпадать');
    }
    if (values.beginSchedule === '') {
      validComplete = false;
      formErrors.beginSchedule = false;
      showAlert('error', 'Поле Дата не должно быть пустым');
    }
    if (values.timeBegin === '') {
      validComplete = false;
      formErrors.timeBegin = false;
      showAlert('error', 'Поле Время не должно быть пустым');
    }
    if (values.timeEnd === '') {
      validComplete = false;
      formErrors.timeEnd = false;
      showAlert('error', 'Поле Время не должно быть пустым');
    }
    if (values.bet === '') {
      validComplete = false;
      formErrors.bet = false;
      showAlert('error', 'Поле Процент не должно быть пустым');
    }
    if (values.lift_compensation === null) {
      validComplete = false;
      formErrors.lift_compensation = false;
      showAlert('error', 'Поле Процент с допродаж не должно быть пустым');
    }
    if (values.cities.length === 0) {
      validComplete = false;
      formErrors.cities = false;
      showAlert('error', 'Поле Города не должно быть пустым');
    }
    if (values.tags.length === 0) {
      validComplete = false;
      formErrors.skills = false;
      showAlert('error', 'Поле Навыки не должно быть пустым');
    }
    if (values.role === '') {
      validComplete = false;
      formErrors.role = false;
      showAlert('error', 'Поле роль не должно быть пустым');
    }

    setErrors(formErrors);
    return validComplete;
  };

  const clearForm = () => {
    setValues({
      phone: '',
      username: '',
      fio: '',
      generalCompensation: 0,
      schedule: '',
      beginSchedule: '',
      timeBegin: '',
      timeEnd: '',
      password: '',
      confirm: '',
      cities: [],
      bet: '',
      tags: [],
      lift_compensation: null,
      full_time: false,
      role: ''
    });
  };

  const formateDate = (value) => {
    return `${value}:00:00`;
  };

  const submit = () => {
    if (validate()) {
      setIsShowLoader(true);
      setSubmitDisabled(true);

      const data = {
        username: values.username.trim(),
        role:
          values.role === 'Regional manager' ? 'regional_manager' : values.role,
        password: values.password
      };

      postU('admin/users', data)
        .then((resp) => {
          if (resp.status === 'success') {
            setSuccess(true);
            return resp.data.user.id;
          } else {
            showAlert('error', `Ошибка создания пользователя: ${resp.message}`);
            setSuccess(false);
          }
        })
        .then((id) => {
          setValues({ ...values, master_id: id });
        })
        .then(() => {
          setCheckForSendParam((prev) => !prev);
        })
        .catch((err) => {
          showAlert('error', `Ошибка создания пользователя: ${err.message}`);
        });
    }
  };

  useEffect(() => {
    const param = {
      master_id: values.master_id,
      schedule: values.schedule,
      beginSchedule: values.beginSchedule,
      timeBegin: formateDate(values.timeBegin),
      timeEnd: formateDate(values.timeEnd),
      cities: values.cities.join(','),
      tags: values.tags.join(','),
      bet: values.bet,
      fio: values.fio,
      generalCompensation: values.generalCompensation,
      lift_compensation: values.lift_compensation,
      full_time: values.role === 'Regional manager' ? false : values.full_time,
      phone:
        values.phone.length > 16
          ? values.phone.substring(0, values.phone.length - 1)
          : values.phone
    };

    if (checkForSendParam === true && param.master_id !== null && success) {
      postU('admin/users/param', param)
        .then((resp) => {
          if (resp.status === 'success') {
            showAlert('success', 'Мастер добавлен');
            clearForm();
            navigate(-1);
          } else {
            showAlert(
              'error',
              `Ошибка создания параметров пользователя: ${resp.message}`
            );
          }
        })
        .catch((err) => {
          showAlert(
            'error',
            `Ошибка создания параметров пользователя: ${err.message}`
          );
        })
        .finally(() => {
          setIsShowLoader(false);
          setSubmitDisabled(false);
        });
    }
  }, [checkForSendParam]);

  useEffect(() => {
    setIsLoaded(true);

    getU(`region`)
      .then((resp) => {
        if (resp.status === 'success') {
          setCities(resp.data.region.map((city) => city.region_name));
        }
      })
      .catch(() => {
        showAlert(
          'error',
          'Произошла ошибка при загрузке городов, попробуйте перезайти'
        );
      });

    getU(`ticket_category`)
      .then((resp) => {
        if (resp.status === 'success') {
          setTags(resp.data.category.map((tag) => tag.category_name));
        }
      })
      .catch(() => {
        showAlert(
          'error',
          'Произошла ошибка при загрузке навыков, попробуйте перезайти'
        );
      })
      .finally(() => {
        setIsLoaded(false);
      });
  }, []);

  if (isLoaded) {
    return (
      <div className="loader">
        <BallTriangle
          height="100"
          width="100"
          color="grey"
          ariaLabel="loading"
        />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Create new user</title>
      </Helmet>
      <Box sx={{ pt: 2 }}>
        <Container maxWidth={false}>
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Назад
          </Button>
        </Container>
      </Box>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          mb: 5
        }}
      >
        <Container maxWidth={false}>
          <Box sx={{ pt: 2 }}>
            <form>
              <Card>
                <CardHeader title="Добавление нового мастера22" />
                <Divider />
                <CardContent sx={{ position: 'relative' }}>
                  <TextField
                    fullWidth
                    label="Имя пользователя"
                    margin="normal"
                    name="username"
                    onChange={handleChange}
                    type="text"
                    value={values.username}
                    variant="outlined"
                    error={errors.username}
                  />
                  <TextField
                    fullWidth
                    label="ФИО пользователя"
                    margin="normal"
                    name="fio"
                    onChange={handleChange}
                    type="text"
                    value={values.fio}
                    variant="outlined"
                    error={errors.fio}
                  />
                  <TextField
                    fullWidth
                    // label="Phone number"
                    margin="normal"
                    name="Phone number"
                    onChange={handleChangePhone}
                    type="text"
                    placeholder="+7 (900)-000-00-00"
                    value={values.phone}
                    variant="outlined"
                    error={errors.phone}
                  />
                  <TextField
                    fullWidth
                    label="Пароль"
                    margin="normal"
                    name="password"
                    onChange={handleChange}
                    type="password"
                    value={values.password}
                    variant="outlined"
                    error={errors.password}
                  />
                  <TextField
                    fullWidth
                    label="Подтверждение пароля"
                    margin="normal"
                    name="confirm"
                    onChange={handleChange}
                    type="password"
                    value={values.confirm}
                    variant="outlined"
                    error={errors.confirm}
                  />
                  <TextField
                    fullWidth
                    label="Общая компенсация"
                    margin="normal"
                    name="generalCompensation"
                    onChange={handleChange}
                    type="number"
                    value={values.generalCompensation}
                    variant="outlined"
                    error={errors.generalCompensation}
                    inputProps={{
                      min: '0'
                    }}
                  />
                  <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                    <InputLabel id="schedule">Рабочий график</InputLabel>
                    <Select
                      labelId="Schedule"
                      name="schedule"
                      value={values.schedule}
                      label="Рабочий график"
                      onChange={handleChange}
                    >
                      {workingSchedule?.map((item, i) => (
                        <MenuItem value={item} key={i}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                    <InputLabel id="role">Роль</InputLabel>
                    <Select
                      labelId="Role"
                      name="role"
                      value={values.role}
                      label="Роль"
                      onChange={handleChange}
                    >
                      {roles?.map((item, i) => (
                        <MenuItem value={item} key={i}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    sx={{ width: 250, mr: 5 }}
                    margin="normal"
                    id="beginSchedule"
                    label="Дата начала отсчета графика"
                    type="date"
                    name="beginSchedule"
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      max: '9999-12-31'
                    }}
                  />
                  <TextField
                    sx={{ width: 120, mr: 5 }}
                    margin="normal"
                    id="timeBegin"
                    label="Время начала"
                    type="number"
                    name="timeBegin"
                    onChange={handleChange}
                    defaultValue={'0'}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      max: '23',
                      min: '0'
                    }}
                  />
                  <TextField
                    sx={{ width: 120, mr: 5 }}
                    margin="normal"
                    id="timeEnd"
                    label="Время окончания"
                    type="number"
                    name="timeEnd"
                    onChange={handleChange}
                    defaultValue={'0'}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      max: '23',
                      min: '1'
                    }}
                  />
                  <TextField
                    sx={{ width: 100, mr: 5 }}
                    margin="normal"
                    id="bet"
                    label="Процент %"
                    type="number"
                    name="bet"
                    onChange={handleChange}
                    defaultValue={'0'}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      max: '100',
                      min: '0'
                    }}
                  />
                  <TextField
                    sx={{ width: 175, mr: 5 }}
                    margin="normal"
                    id="lift_compensation"
                    label="Процент с допродаж %"
                    type="number"
                    name="lift_compensation"
                    onChange={handleChange}
                    defaultValue={'0'}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      max: '100',
                      min: '0'
                    }}
                  />

                  {values.role !== 'Regional manager' && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.full_time}
                          onChange={() =>
                            setValues((prev) => ({
                              ...prev,
                              full_time: !prev.full_time
                            }))
                          }
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label="Штатный сотрудник"
                      labelPlacement="bottom"
                    />
                  )}
                  <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                    <InputLabel id="cities">Города</InputLabel>
                    <Select
                      labelId="cities"
                      name="cities"
                      value={values.cities}
                      label="Города"
                      multiple
                      renderValue={(selected) => selected.join(', ')}
                      onChange={handleChange}
                    >
                      {cities?.map((city, index) => (
                        <MenuItem value={city} key={index}>
                          <Checkbox
                            checked={
                              city === values.cities.find((el) => el === city)
                            }
                          />
                          <ListItemText primary={city} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
                    <InputLabel id="tags">Навыки</InputLabel>
                    <Select
                      labelId="tags"
                      name="tags"
                      value={values.tags}
                      label="Навыки"
                      multiple
                      renderValue={(selected) => selected.join(', ')}
                      onChange={handleChange}
                    >
                      {tags?.map((tag, i) => (
                        <MenuItem value={tag} key={i}>
                          <Checkbox
                            checked={
                              tag === values.tags.find((el) => el === tag)
                            }
                          />
                          <ListItemText primary={tag} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Alert
                    severity={alert.type}
                    style={{ display: alert.isVisible ? 'flex' : 'none' }}
                  >
                    {alert.txt}
                  </Alert>
                </CardContent>
                <Divider />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    p: 2
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={submit}
                    disabled={submitDisabled}
                  >
                    Добавить
                  </Button>
                </Box>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default MasterAdd;
